<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <h3>{{ user.usu_nome }}</h3>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'users'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Tipo:</th>
                <td width="90%" colspan="7">{{ user.usu_tipo }}</td>
            </tr>
            <tr>
                <th width="10%">Nome:</th>
                <td width="24%" colspan="2">{{ user.usu_nome }}</td>
                <th width="10%">CPF/CNPJ:</th>
                <td width="23%" colspan="2">{{ (user.usu_cpf) ? user.usu_cpf : user.usu_cnpj }}</td>
                <th width="10%">RG/IE:</th>
                <td width="23%" colspan="2">{{ (user.usu_rg) ? user.usu_rg : user.usu_ie }}</td>
            </tr>
            <tr>
                <th width="10%">Suframa:</th>
                <td width="90%" colspan="3">{{ user.usu_suframa }}</td>
                <th width="10%">Atividade:</th>
                <td width="90%" colspan="3">{{ user.usu_atividade }}</td>
            </tr>
            <tr>
                <th width="10%">CNAE:</th>
                <td width="90%" colspan="3">{{ user.usu_cnae }}</td>
                <th width="10%">Data Abertura:</th>
                <td width="90%" colspan="3">{{ user.usu_data_abertura }}</td>
            </tr>
            <tr>
                <th width="10%">Celular:</th>
                <td width="24%" colspan="2">({{ user.usu_prefixo }}) {{ user.usu_celular }}</td>
                <th width="10%">Telefone 1:</th>
                <td width="23%" colspan="2">({{ user.usu_prefixo }}) {{ user.usu_fone1 }}</td>
                <th width="10%">Telefone 2:</th>
                <td width="23%" colspan="2">({{ user.usu_prefixo }}){{ user.usu_fone2 }}</td>
            </tr>
            <tr>
                <th width="10%">Email:</th>
                <td width="90%" colspan="7">{{ user.email }}</td>
            </tr>
            <tr>
                <th width="10%">Simples Nacional:</th>
                <td width="90%" colspan="7">{{ (user.usu_simples_nac == '1') ? 'SIM' : 'NÃO' }}</td>
            </tr>
            <tr>
                <th width="10%">Status</th>
                <td width="90%" colspan="7" :class="(user.usu_status == '1') ? 'verde' : 'vermelho' ">{{ (user.usu_status == '1') ? 'ATIVO' : 'INATIVO' }}</td>
            </tr>
            <tr class="grey darken-4">
                <td colspan="8">ENDEREÇOS</td>
            </tr>
        </tbody>
    </table>

    <v-data-table :headers="headers" :items="user.enderecos" hide-actions>
        <template v-slot:items="props">
            <td class="grey darken-3">{{ props.item.user_ad_contato }}</td>
            <td class="text-xs-center grey darken-3">{{ props.item.user_ad_tipo }}</td>
            <td class="text-xs-center grey darken-3">{{ props.item.user_ad_finalidade }}</td>
            <td class="grey darken-3">{{ props.item.user_ad_endereco }}, {{ props.item.user_ad_numero }}</td>
            <td class="grey darken-3">{{ props.item.user_ad_bairro }}</td>
            <td class="grey darken-3">{{ props.item.user_ad_complemento }}</td>
            <td class="text-xs-center grey darken-3">{{ props.item.user_ad_cep }}</td>
            <td class="grey darken-3">{{ props.item.user_ad_cidade }}</td>
            <td class="text-xs-center grey darken-3">{{ props.item.user_ad_uf }}</td>
            <td class="grey darken-3">
                <v-switch v-model="props.item.user_ad_principal" @change="onChangeEventHandlerPrincipal(props.item.user_ad_id, $event)" class="my-switch" readonly></v-switch>
            </td>
            <td class="grey darken-3">
                <v-switch v-model="props.item.user_ad_status" @change="onChangeEventHandlerStatus(props.item.user_ad_id, $event)" class="my-switch" readonly></v-switch>
            </td>
        </template>
    </v-data-table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailUserComponent",
    created() {
        this.loadUser()
    },
    props: {
        usu_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            headers: [{
                    sortable: false,
                    text: 'Contato',
                    value: 'user_ad_contato'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Tipo',
                    value: 'user_ad_tipo'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Finalidade',
                    value: 'user_ad_finalidade'
                },
                {
                    sortable: false,
                    text: 'Endereço',
                    value: 'user_ad_endereco'
                },
                {
                    sortable: false,
                    align: 'Left',
                    text: 'Bairro',
                    value: 'user_ad_bairro'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Complemento',
                    value: 'user_ad_complemento'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'CEP',
                    value: 'user_ad_cep'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Cidade',
                    value: 'user_ad_cidade'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'UF',
                    value: 'user_ad_uf'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Principal',
                    value: 'user_ad_principal'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Status',
                    value: 'user_ad_status'
                },
            ],
            user: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadUser() {
            this.$store.dispatch('loadUser', this.usu_id)
                .then(response => {
                    this.user = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.verde {
    color: chartreuse;
}

.vermelho {
    color: tomato;
}
</style>
